import { Component, forwardRef, Input, output } from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, type Subscription } from 'rxjs';
import { NgTemplateOutlet } from '@angular/common';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';

import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent } from '@design/forms/checkbox/checkbox.component';
import * as R from 'ramda';

export interface TreeviewPickerOption {
  value: any;
  label: string;
  expanded?: boolean;
  areAnyChildrenSelected?: boolean;
  areAllChildrenSelected?: boolean;
  suboptions?: TreeviewPickerOption[];
}

@Component({
  selector: 'treeview-picker',
  templateUrl: './treeview-picker.component.html',
  styleUrls: ['./treeview-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TreeviewPickerComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgTemplateOutlet, FocusableDirective, AssetSrcDirective, TranslateModule, CheckboxComponent],
})
export class TreeviewPickerComponent implements ControlValueAccessor {
  @Input() options: TreeviewPickerOption[];
  @Input() onlyLowLevel = false;
  @Input() singleSelect = false;
  @Input() includeParent = false;
  @Input() clearEvent: Observable<void>;
  selectionChange = output();

  public isDisabled = false;
  public optionsCopy: TreeviewPickerOption[] = [];
  private clearEventSubscription: Subscription;
  private _value: string[] = [];

  public get value(): string[] {
    return this._value;
  }

  public set value(val: string[]) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
    this.selectionChange.emit();
  }

  public ngOnInit(): void {
    this.clearEventSubscription = this.clearEvent?.subscribe(() => {
      this.clearFilterRecursive(this.optionsCopy);
    });

    this.optionsCopy = R.clone(this.options);
    if (this.onlyLowLevel) {
      setTimeout(() => {
        this.markParents();
      }, 0);
    }
  }

  ngOnDestroy() {
    this.clearEventSubscription?.unsubscribe();
  }

  public isOptionSelected(option: TreeviewPickerOption): boolean {
    if (!this.value) {
      return false;
    }

    if (this.onlyLowLevel && option.suboptions) {
      return option.areAllChildrenSelected;
    } else {
      const index = this.value.findIndex((val) => val === option.value);
      return index !== -1;
    }
  }

  public isOptionIntermediate(option: TreeviewPickerOption): boolean {
    return option.areAnyChildrenSelected && !option.areAllChildrenSelected;
  }

  public changeSelection(event: Event, option: TreeviewPickerOption): void {
    const checked = (event.target as HTMLInputElement).checked;

    if (this.singleSelect) {
      if (this.isOptionSelected(option)) {
        this.value = [];
      } else {
        this.value = [option.value];
      }
      return;
    }

    if (this.onlyLowLevel) {
      this.onlyLowLevelSelection(checked, option);
    } else {
      this.regularSelection(checked, option);
    }
  }

  public writeValue(value: string[]): void {
    this._value = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (val: string[]) => {};

  private onTouched = () => {};

  private findChildren(opt: TreeviewPickerOption): TreeviewPickerOption[] {
    const results: TreeviewPickerOption[] = [];

    const addChildrenToResults = (child: TreeviewPickerOption) => {
      if (!this.onlyLowLevel || !child.suboptions) {
        results.push(child);
      }
      if (child.suboptions) {
        child.suboptions.forEach((subchild) => {
          addChildrenToResults(subchild);
        });
      }
    };
    addChildrenToResults(opt);
    return results;
  }

  private findParent(option: TreeviewPickerOption): TreeviewPickerOption {
    let parent;
    const findParent = (opt: TreeviewPickerOption, arr: TreeviewPickerOption[]) => {
      return arr.find((val) => {
        if (!val.suboptions) {
          return null;
        }
        if (val.suboptions.findIndex((subopt) => subopt.value === opt.value) !== -1) {
          parent = val;
          return val;
        } else {
          return findParent(opt, val.suboptions);
        }
      });
    };
    findParent(option, this.optionsCopy);
    return parent;
  }

  private onlyLowLevelSelection(selected: boolean, option: TreeviewPickerOption): void {
    if (!selected) {
      const optionsToExclude = [];

      if (option.suboptions) {
        const children = this.findChildren(option);
        optionsToExclude.push(...children.map((opt) => opt.value));
      } else {
        optionsToExclude.push(option.value);
      }

      this.value = this.value.filter((val) => !optionsToExclude.includes(val));
    } else {
      const optionsToInclude = [];

      if (option.suboptions) {
        const children = this.findChildren(option);
        optionsToInclude.push(...children.map((child) => child.value).filter((val) => !this.value.includes(val)));
      } else {
        optionsToInclude.push(option.value);
      }

      if (this.includeParent) {
        const includeWithParents = (opt: TreeviewPickerOption) => {
          if (!this.value.includes(opt.value)) {
            optionsToInclude.push(opt.value);
          }

          const parent = this.findParent(opt);
          if (parent) {
            includeWithParents(parent);
          }
        };
        includeWithParents(option);
      }

      this.value = [...this.value, ...optionsToInclude];
    }

    this.markParents();
  }

  private markParents(): void {
    if (!this.optionsCopy) {
      return;
    }
    const checkChildren = (options: TreeviewPickerOption[]) => {
      options.forEach((opt) => {
        if (!opt.suboptions) {
          return;
        }
        const children = this.findChildren(opt);
        const selectedChildren = children.filter((child) => this.value.includes(child.value));
        opt.areAnyChildrenSelected = selectedChildren.length > 0;
        opt.areAllChildrenSelected = selectedChildren.length === children.length;
        checkChildren(opt.suboptions);
      });
    };
    checkChildren(this.optionsCopy);
  }

  private regularSelection(selected: boolean, option: TreeviewPickerOption): void {
    if (!selected) {
      const optionsToExclude = this.findChildren(option).map((opt) => opt.value);

      this.value = this.value.filter((val) => !optionsToExclude.includes(val));
    } else {
      const optionsToInclude = [];

      const includeWithParents = (opt: TreeviewPickerOption) => {
        if (!this.value.includes(opt.value)) {
          optionsToInclude.push(opt.value);
        }

        const parent = this.findParent(opt);
        if (parent) {
          includeWithParents(parent);
        }
      };
      includeWithParents(option);

      this.value = [...this.value, ...optionsToInclude];
    }
  }

  private clearFilterRecursive(options: TreeviewPickerOption[]): void {
    options.forEach((opt) => {
      opt.areAnyChildrenSelected = false;
      opt.areAllChildrenSelected = false;
      if (opt.suboptions) {
        this.clearFilterRecursive(opt.suboptions);
      }
    });
  }
}
